//export const API_URL = "http://localhost:5000";      /* Localhost */

//export const API_URL = "http://18.217.35.75:3079";    /* dev */

//export const API_URL ="http://18.222.154.60:3025"      /* uat */

export const API_URL ="https://backend.leboco.com"      /* uat new */

//export const API_URL ="https://backend.brohandy.com"   /* production */

export const IMG_URL = "https://mynewsbuckets.s3.ca-central-1.amazonaws.com/";

//export const FRONTEND_URL = "http://18.217.35.75:3078";    /* dev */

export const FRONTEND_URL ="https://leboco.com"      /* uat new */

//export const FRONTEND_URL ="https://brohandy.com" /* production */
